body {
  margin: 0px;
}

/* Estilo para inputs deshabilitados */
.ant-input[disabled],
.ant-input-number[disabled],
.ant-select-disabled .ant-select-selector,
.ant-picker-disabled .ant-picker-input,
.ant-input-number-disabled .ant-input-number-input {
  color: rgba(0, 0, 0, 0.5) !important; /* Un color de fondo más claro */
  opacity: 1 !important; /* Asegúrate de que la opacidad no se vea afectada */
  cursor: not-allowed !important; /* Asegúrate de que el cursor se vea como no permitido */
}
